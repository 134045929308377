<template>
<div>
    <v-card>
        <v-toolbar flat>
            <v-toolbar-title>
                <h4 style="font-weight:500">Settings</h4>
            </v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
    </v-card>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" xl="6">
                <v-card>
                    <v-tabs>
                        <v-tab>SMTP</v-tab>
                        <v-tab>Time Zone</v-tab>

                        <v-tab-item>
                            <v-card flat>
                                <v-container>
                                    <v-row justify="center">
                                        <v-col cols="12" xl="6">
                                            <v-subheader>STMP settings used for email notifications</v-subheader>
                                            <v-col cols="12">
                                                <v-text-field label="Host" v-model="myCompany.settings.smtp.host"></v-text-field>
                                            </v-col>

                                            <v-col cols="12">
                                                <v-text-field label="Port" v-model="myCompany.settings.smtp.port"></v-text-field>
                                            </v-col>

                                            <v-col cols="12">
                                                <v-text-field label="Username" v-model="myCompany.settings.smtp.username"></v-text-field>
                                            </v-col>

                                            <v-col cols="12">
                                                <v-text-field label="Password" type="password" v-model="myCompany.settings.smtp.password"></v-text-field>
                                            </v-col>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-tab-item>

                        <v-tab-item>
                            <v-card flat>
                                <v-row justify="center">
                                    <v-col cols="12" xl="6">
                                        <v-subheader>Cron tasks executed with selected timezone</v-subheader>
                                        <v-col cols="12">
                                            <v-select :items="timezones" label="Local timezone" v-model="myCompany.settings.timezone"></v-select>
                                        </v-col>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-tab-item>
                    </v-tabs>
                    <v-container>
                        <v-row>
                            <v-col class="text-center">
                                <v-btn color="primary" :loading="isPatchingUser" @click="saveUser">
                                    save
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>

import {
    mapGetters,
    mapState,
    mapActions
} from 'vuex';

import timezones from '@/components/timezones.js';

export default {
    name: 'Settings',
    data() {
        return {
            timezones,
            myCompany: {
                settings: {
                    ftp: {
                        filename: '',
                        host: '',
                        username: '',
                        password: '',
                    },
                    smtp: {
                        host: '',
                        port: '',
                        username: '',
                        password: '',
                    },
                    timezone: ''
                },
            }
        }
    },
    created() {
        // if (this.user) {
        //     this.myCompany.settings = this.user.user.settings
        // }
    },
    computed: {
        ...mapState('auth', {
            user: 'payload'
        }),
        ...mapState('users', {
            areUsersLoading: 'isFindPending',
            isPatchingUser: 'isPatchPending',
        }),
        ...mapGetters('users', {
            findUsersInStore: 'find'
        }),
        users() {
            return this.findUsersInStore({}).data;
        },
    },
    methods: {
        ...mapActions('users', {
            fetchUsers: 'find',
            removeUser: 'remove',
            createUser: 'create',
            updateUser: 'patch',
        }),
        saveUser() {
            let promise, successMessage;
            promise = this.updateUser([this.user.user._id, this.myCompany, {}]);
            successMessage = 'Record updated';
            promise.then(() => {
                this.$toasted.global.success({
                    message: successMessage
                });
                this.showUserModal = false;
            }).catch(errors => {
                this.$toasted.global.error({
                    message: errors.message
                });
            })
        },
    },

}
</script>

<style>

</style>
